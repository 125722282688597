import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Overhead Squats 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 13.1`}</strong></p>
    <p>{`17:00 AMRAP of:`}</p>
    <p>{`40-Burpees (touching target 6″ above reach)`}</p>
    <p>{`30-Snatches (75/45)`}</p>
    <p>{`30-Burpees`}</p>
    <p>{`30-Snatches (135/75)`}</p>
    <p>{`20-Burpees`}</p>
    <p>{`30-Snatches (165/100)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`30-Snatches (210/120)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Sign up now at
Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start February 24th.  For more info
email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      